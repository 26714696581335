<template>
	<div class="dashboard">
		<AppTopbar
		title="Terms and Conditions"
		buttonText="New Order"
		buttonLink="new"
		buttonIconClass="icon-add"
		></AppTopbar>

		<div class="container-fluid position-relative pt-64 pt-md-90">
			<div class="card-wrapper">

				<b-link href="https://shyplite.com/privacy" target="_blank" class="card card-sm card-sm__header text-decoration-none">
					<div class="card-sm__header">
						<div class="icon-wrap fs20 blue">
							<i class="icon-url"></i>
						</div>
						<span class="text-gray-600 fs20"> Privacy Policy </span>
					</div>
				</b-link>

				<b-link href="https://shyplite.com/tou" target="_blank" class="card card-sm card-sm__header text-decoration-none">
					<div class="card-sm__header">
						<div class="icon-wrap fs20 blue">
							<i class="icon-url"></i>
						</div>
						<span class="text-gray-600 fs20"> Terms of Service </span>
					</div>
				</b-link>

				<b-link :href="items[0]?items[0].doclink:'#'" target="_blank" class="card card-sm card-sm__header text-decoration-none">
					<div class="card-sm__header">
						<div class="icon-wrap fs20 blue">
							<i class="icon-url"></i>
						</div>
						<span class="text-gray-600 fs20"> Agreement </span>
					</div>
				</b-link>

			</div>
		</div>

		<div class="container-fluid">
			<div class="card">
				<div class="card__title fs18 fw500">Acceptance Log</div>
				<div>
					<!-- <p>Given below is the list of Shyplite agreement of service or any legal document that have been accepted by you as a user.</p> -->
					<b-table v-if="items.length>0" :items="items" :fields="fields" class="mt-20" sticky-header no-border-collapse id="manifests-table">
						<template v-slot:cell(version)="data">
							<div class="w74 d-block">
								<b-link :href="data.item.doclink" target="_blank">{{ data.item.version }}</b-link>
							</div>
						</template>
						<template v-slot:cell(description)="data">
							<span v-html="data.item.description">
							</span>
						</template>
						<template v-slot:cell(user)="data">
							<span>
								{{ $store.getters.user.sellerName }}
							</span>
						</template>
						<template v-slot:cell(status)="data">
							<span class="tag" :class="{'delivered':data.item.status=='Accepted', 'error':data.item.status=='Pending'}">
								{{ data.item.status }}
							</span>
						</template>
						<template v-slot:cell(date)="data">
							<span>
								{{ data.item.date | date }}
							</span>
						</template>
						<template v-slot:cell(published)="data">
							<span>
								{{ data.item.published | date }}
							</span>
						</template>
					</b-table>
					<div v-else class="p-20 text-red">
						<p>{{ error }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";

export default {
	name: "PincodeChecks",
	title: "Shyplite - Pin Code Check",
	data() {
		return {
			fields: [
			{ key: "name", label: "Document Name" },
			{ key: "version", label: "Version No." },
			{ key: "description", label: "Change Description" },
			{ key: "user", label: "User" },
			{ key: "date", label: "Acceptance Date" },
			{ key: "published", label: "Published On" },
			{ key: "status"},
			],
			items: [],
			error: '',
		};
	},
	components: {
		AppTopbar,
	},
	created () {
		this.getItems();
	},
	methods: {
		async getItems() {
			try {
				const res = await this.axios.get('/agreements')
				console.log(res.data);
				if(res.data.status === true) {
					this.items = res.data.agreements
				}else {
					this.error = res.data.message
					this.popToast("failed", "Failed!", res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		}
	},
};
</script>

<style>
.b-table-sticky-header {
	max-height: 370px;
}
.b-table tbody tr:first-child td {
	vertical-align: top !important;
}
</style>