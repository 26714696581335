var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Terms and Conditions",
          buttonText: "New Order",
          buttonLink: "new",
          buttonIconClass: "icon-add"
        }
      }),
      _c(
        "div",
        { staticClass: "container-fluid position-relative pt-64 pt-md-90" },
        [
          _c(
            "div",
            { staticClass: "card-wrapper" },
            [
              _c(
                "b-link",
                {
                  staticClass:
                    "card card-sm card-sm__header text-decoration-none",
                  attrs: {
                    href: "https://shyplite.com/privacy",
                    target: "_blank"
                  }
                },
                [
                  _c("div", { staticClass: "card-sm__header" }, [
                    _c("div", { staticClass: "icon-wrap fs20 blue" }, [
                      _c("i", { staticClass: "icon-url" })
                    ]),
                    _c("span", { staticClass: "text-gray-600 fs20" }, [
                      _vm._v(" Privacy Policy ")
                    ])
                  ])
                ]
              ),
              _c(
                "b-link",
                {
                  staticClass:
                    "card card-sm card-sm__header text-decoration-none",
                  attrs: { href: "https://shyplite.com/tou", target: "_blank" }
                },
                [
                  _c("div", { staticClass: "card-sm__header" }, [
                    _c("div", { staticClass: "icon-wrap fs20 blue" }, [
                      _c("i", { staticClass: "icon-url" })
                    ]),
                    _c("span", { staticClass: "text-gray-600 fs20" }, [
                      _vm._v(" Terms of Service ")
                    ])
                  ])
                ]
              ),
              _c(
                "b-link",
                {
                  staticClass:
                    "card card-sm card-sm__header text-decoration-none",
                  attrs: {
                    href: _vm.items[0] ? _vm.items[0].doclink : "#",
                    target: "_blank"
                  }
                },
                [
                  _c("div", { staticClass: "card-sm__header" }, [
                    _c("div", { staticClass: "icon-wrap fs20 blue" }, [
                      _c("i", { staticClass: "icon-url" })
                    ]),
                    _c("span", { staticClass: "text-gray-600 fs20" }, [
                      _vm._v(" Agreement ")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card__title fs18 fw500" }, [
            _vm._v("Acceptance Log")
          ]),
          _c(
            "div",
            [
              _vm.items.length > 0
                ? _c("b-table", {
                    staticClass: "mt-20",
                    attrs: {
                      items: _vm.items,
                      fields: _vm.fields,
                      "sticky-header": "",
                      "no-border-collapse": "",
                      id: "manifests-table"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(version)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "w74 d-block" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        href: data.item.doclink,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(data.item.version))]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(description)",
                          fn: function(data) {
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(data.item.description)
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(user)",
                          fn: function(data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$store.getters.user.sellerName) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(status)",
                          fn: function(data) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "tag",
                                  class: {
                                    delivered: data.item.status == "Accepted",
                                    error: data.item.status == "Pending"
                                  }
                                },
                                [_vm._v(" " + _vm._s(data.item.status) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(date)",
                          fn: function(data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("date")(data.item.date)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "cell(published)",
                          fn: function(data) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("date")(data.item.published)
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1262274482
                    )
                  })
                : _c("div", { staticClass: "p-20 text-red" }, [
                    _c("p", [_vm._v(_vm._s(_vm.error))])
                  ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }